<template>
  <div :class="{ 'is-expired': job.isExpired }">
    <v-row
      no-gutters
      :id="`job-${job.id}`"
      class="w-100 pa-4 pa-lg-10 job-container"
      :class="job.jobCover.mediaPath ? '' : 'mt-lg-5'"
    >
      <v-col cols="12">
        <v-row no-gutters>
          <v-col lg="6" md="6" cols="12" v-if="job.jobCover.mediaPath">
            <img
              :src="job.jobCover.mediaPath"
              alt=""
              class="job-image_wrapper"
              crossorigin="anonymous"
              @click="$emit('openImageModal')"
            />
            <!-- <div 
            class="job-image_wrapper" 
            :style="{
              'background-image': `url(${job.jobCover.mediaPath})`
            }"
          >
          </div> -->
          </v-col>
          <v-col
            :lg="job.jobCover.mediaPath ? 6 : 12"
            :md="job.jobCover.mediaPath ? 6 : 12"
          >
            <div class="pt-3 d-flex align-center">
              <router-link
                class="text-decoration-none white--text align-center"
                :to="{
                  name: routeName.PROFILE,
                  query: {
                    id: job.producerUid //temp putting job id, suppose to be producer_id
                  }
                }"
                target="_blank"
              >
                <!-- verification-status -->
                <span class="verification-status">
                  <v-avatar size="50">
                    <img :src="getPhoto(job)" alt="" crossorigin="anonymous" />
                  </v-avatar>

                  <v-icon
                    color="#FF2FF2"
                    class="icon"
                    v-if="job.producerIsVerified"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </span>
                <span class="pl-3">{{ job.producer }}</span>
              </router-link>
              <v-menu dark left z-index="1" offset-y v-if="hasReportBtn">
                <template v-slot:activator="{ on }">
                  <v-icon dark v-on="on" class="ml-auto"
                    >mdi-dots-horizontal</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item @click="report">
                    <v-list-item-title class="px-8">
                      {{ $t('label.report') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="pt-3 job-title">{{ job.title }}</div>
            <div class="pt-3">
              <span class="deep-blue--text">{{ job.projectTypeId.name }}</span>
              <span class="pl-3">Paid Job</span>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <div>
            <div class="pink2--text mb-1">Synopsis</div>
            <div>
              {{ job.synopsis }}
            </div>
          </div>
        </v-row>
        <v-row no-gutters class="pt-md-6 pt-3">
          <v-col lg="6" md="6" cols="12">
            <div class="pink2--text mb-1">Character / Role</div>
            <template v-for="(role, index) in job.castingRoles">
              <div :key="index">
                {{ role.character }}
                (On-screen Age {{ role.ageRange }})
              </div>
            </template>
          </v-col>
          <v-col lg="3" md="3" cols="12" class="pt-3 pt-md-0">
            <div class="pink2--text">Job Location</div>
            <div
              v-for="(jobCountryId, x) in job.jobCountryId"
              :key="x"
              class="d-flex align-center pt-2"
            >
              <img
                :src="jobCountryId.imgCircle"
                alt=""
                width="30px"
                class="mr-5"
                crossorigin="anonymous"
              />
              <div>{{ jobCountryId.name }}</div>
            </div>
          </v-col>
          <v-col lg="3" md="3" cols="12" class="pt-3 pt-md-0">
            <div class="pink2--text">Preferred Country of Applicant</div>
            <div
              v-for="(appCountryId, x) in job.applicantCountryId"
              :key="x"
              class="d-flex align-center pt-2"
            >
              <img
                :src="appCountryId.imgCircle"
                alt=""
                width="30px"
                class="mr-5"
                crossorigin="anonymous"
              />
              <div>{{ appCountryId.name }}</div>
            </div>
          </v-col>
        </v-row>
        <hr class="divider my-5" />
        <v-row no-gutters class="pt-md-6">
          <v-col cols="12" lg="9" md="9">
            <v-row no-gutters class="pb-md-0 pb-4">
              <v-col cols="6" md="3">
                <div class="pink2--text">Video Submission Date</div>
                <div>{{ job.videoDue | defaultDate }}</div>
              </v-col>
              <v-col cols="6" md="3" class="pl-2">
                <div class="pink2--text">Closing Date</div>
                <div>{{ job.closeDate | defaultDate }}</div>
              </v-col>
              <v-col cols="6" md="3" class="pt-md-0 pt-2">
                <div class="pink2--text">Posting Date</div>
                <div>{{ job.liveDate | defaultDate }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3" md="3" class="text-center align-self-center">
            <router-link
              class="text-decoration-none my-10"
              :to="{
                name: routeName.JOB_DETAILS,
                slug: job.id,
                params: {
                  id: job.id,
                  redirect: 'HOME'
                }
              }"
            >
              <button
                class="btn1 px-10 py-2"
                v-text="$t('label.moreDetails')"
              ></button>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { ROUTE_NAME } from '@/constants';
  import { bus } from '@/main.js';

  export default {
    name: 'JobPostBox',
    props: {
      job: {
        type: Object,
        default: () => ({})
      },
      hasReportBtn: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        routeName: ROUTE_NAME
      };
    },
    computed: {
      defaultProfilePhoto() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      }
    },
    methods: {
      getPhoto(job) {
        return job?.producerCompanyLogo?.mediaPath || this.defaultProfilePhoto;
      },
      report() {
        bus.$emit('report', {
          id: this.job.id,
          reportType: 'casting'
        });
      }
    }
  };
</script>

<style>
  .is-expired {
    opacity: 0.3;
  }
  .is-expired:hover {
    opacity: 0.7;
  }

  .verification-status {
    position: relative;
  }

  .verification-status > .icon {
    position: absolute;
    right: -6px;
    bottom: -24px;
  }
</style>
