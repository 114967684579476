<template>
  <div class="container wrapper wrapper_space-top">
    <h1 class="pageHeader">
      {{ $t('label.postedJob') }}
    </h1>
    <div class="job-list pb-12 mb-12" v-if="jobs.length">
      <template v-for="(job, index) in jobs">
        <JobPostBox
          :key="index"
          :job="job"
          @openImageModal="showJobCoverImage = job.jobCover.mediaPath; showJobCoverImageModal = true;"
        />
      </template>
      <div v-observe-visibility="scrolledToBottom"></div>
    </div>
    <div v-else>No posted job.</div>
    <AppImageModal
      :show="showJobCoverImageModal"
      :image="showJobCoverImage"
      @close="showJobCoverImageModal = false; showJobCoverImage = null;"
    />
  </div>
</template>

<script>
  import {
    HOME_SCREENS_GET_OWN_JOBS,
    HOME_SCREENS_INITIAL_GET_OWN_JOBS_STATE
    // INITIAL_DELETE_STATE
  } from '@/store/home-screen.module';
  import { ROUTE_NAME } from '@/constants';
  import JobPostBox from '@/components/job/JobPostBox.vue';

  export default {
    name: 'PostedJob',
    components: {
      JobPostBox
    },
    data: () => ({
      jobs: [],
      routeName: ROUTE_NAME,
      perPage: 5,
      showJobCoverImage: null,
      showJobCoverImageModal: false,
    }),
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      getOwnJobsComplete() {
        return this.$store.state.homeScreen.ownJobs.complete;
      }
    },
    watch: {
      getOwnJobsComplete() {
        let response = this.$store.state.homeScreen.ownJobs;
        if (response.complete) {
          this.importOwnJobs(response);
        }
      }
    },
    methods: {
      importOwnJobs(response) {
        console.log(response.data);
        this.jobs = response.data;
        if (this.jobs) {
          this.getOwnJobsCompleteAction();
        }
      },
      getOwnJobsCompleteAction() {
        this.initialGetOwnJobsState();
      },
      initialGetOwnJobsState() {
        this.$store.dispatch(HOME_SCREENS_INITIAL_GET_OWN_JOBS_STATE);
      },
      loadJobs() {
        this.$store.dispatch(HOME_SCREENS_GET_OWN_JOBS, {
          data: { perPage: this.perPage }
        });
      },
      async scrolledToBottom(isVisible) {
        if (!isVisible) {
          return;
        }

        this.perPage += 5;
        this.loadJobs();
      }
    },
    mounted() {
      this.loadJobs();
      if (this.$route.params?.id) {
        setTimeout(() => {
          document
            .getElementById(`job-${this.$route.params?.id}`)
            .scrollIntoView(false);
        }, 1000);
      }
    }
  };
</script>

<style lang="scss">
  
  .lg-font {
    font-size: 1.4rem;
  }
</style>
